.bnApp {
    height: 100%;
    width: 100%;
}

#recaptcha-fun {
   display: none
}

.bnSignInChoice {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: rgb(245, 90, 0);
    color: white;
    height: 30px;
    align-items: center;
    margin-bottom: 5px;
}

