
.bbTrax {
    height: 100%;
    width: 100%;
    background: rgb(31, 31, 31);
}


.bbTraxStage {
    height: calc(100% - 120px);
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
}

.bbTraxCenterStage {
    width: fit-content;
}

.bbTimeline {
    background: rgb(31, 31, 31);
    position: absolute;
    bottom: -20px;
    width: calc(100% - 0px);
    padding-right: 0px;
    padding-left: 0px;
    padding-left: 10px;
    width: calc(100% - 10px);
    overflow-x: auto;
    overflow-y: visible;
    height: 120px;
}

.bbTimelineBotton {
}

.bbTimelineTracks {
    height: 100px;
    position: relative;
}

.bbTimeline::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    width: 0px;
}

.bbTimelineControls {
    background: rgb(51, 51, 51);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 98px;
}

.bbTimelineButtons {
    display: flex;
}
        

.bbTimelinePlayheadTrackActive {
    height: 85px !important;
}

.bbTimelinePlayheadTrack {
    height: 16px;
    position: relative;
}

.bbTimelinePlayhead {
    height: 16px;
    position: absolute;
    width: 100%;
    user-select: none;
    z-index: 10;
}

.bbTimelinePlayhead img {
    pointer-events: none;
}

.bbTimelineBottom {
    height: 100px;
}


.bbTimelinePlayheadTop {
    position: absolute;
    top: -4px;
    left: -8px;
}

.bbTimelinePlayheadMiddle {
    position: absolute;
    top: 8px;
    background-repeat: repeat-y;
    height: 95px;
    width: 8px;
    left: -4px;
    z-index: 10;
}

.bbTraxMenu {
    position: absolute;
    top: 2px;
    left: 2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.bbMoveMenuItem {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    min-height: 20px;
    background: rgb(66,74, 145);
    margin-bottom: 1px;
    color: white;
}

.bbMoveVideo {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.bbMoveVideo video {
    height: 100%;
    object-fit: contain;
}

.bbTimelineTrackList {
    position: absolute;
    top: 16px;
    left: 0;
    display: flex;
    pointer-events: auto;
}

.bbTimelineTrack {
    color: white;
    background: blue;
    display: flex;
    justify-content: space-between;
    border-right: 8px solid rgb(31, 31, 31);
    align-items: center;
    padding-left: 10px;
    padding-right: 0px;
}

.bbTrackTitle {
    position: absolute;
    top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    color: white;
    text-transform: uppercase;
}


.bbPauseButton {
    width: 28px;
    height: 18px;
    background: rgb(77, 77, 77);
    display: flex;
    justify-content: center;
    align-items: center;
}


.bbPauseButtonGraphic {
    display: flex;
}

.bbPauseButtonBar {
    width: 2.25px;
    height: 10px;
    background: rgb(220, 220, 220);
    margin-left: 1px;
    margin-right: 1px;
    filter: blur(0.4px);
}

.bbTimelineTime {
    padding-left: 10px;
    color: white;
    min-width: 100px;
}

.bbMoveMenuItemClip {
    height: 48px;
    width: 27px;
    overflow: hidden;
}

.bbMoveMenuItemClip video {
    height: 48px;
    width: 27px;
    object-fit: contain;
    margin-right: 5px;
    transform: scale(5, 5) translate(2px, -5px);
    overflow: hidden;
}

.bbTimelineTrackDelete {
    cursor: pointer;
}

.bbTimelineTrackDelete svg {
    height: 45px;
}

.bbTimelineTrackDelete svg path {
    fill: white !important;
}

.bbTimelineTrackMarks {
    height: 16px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
