.bnStart {
    font-family: 'apparat', sans-serif;
    font-size: 16px;
    color: #333333;
    width: 100%;
}

.benxtAppDesktop .bnStart {
    font-size: 21px;
}

.bnStartHeader {
    background: rgb(150, 150, 150);
    width: calc(100% - 50px);
    display: flex;
    justify-content: space-between;
    padding:20px;
}

.bnStartLogo svg {
    height: 30px;
}

.bnStartSignInButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.bnStartSignInButtonIcon svg {
    height: 20px;
}

.bnStartSignInButtonIcon svg g ellipse{
    fill: black !important;
}

.bnStartSignInButtonIcon svg g path{
    fill: black !important;
}

.bnStartSignInButtonLabel {
    color: black;
    font-size: 13px;
}

.bnStartBody {
    background: rgb(150, 150, 150);
    width: 100%;
}

.bnStartJoinButton {
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 260px;
    height: 50px;
    margin-left: 20px;
    margin-bottom: 20px;
    box-shadow: 3px 3px 10px grey;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}

.benxtAppDesktop .bnStartJoinButton {
    margin-left: 50px;
}

.bnStartMessage {
    margin-left: 25px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    font-size: 19px;
    font-weight: 300;
}

.bnContent {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
}

.bnAccordion {
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    display: none;
}

.benxtAppDesktop .bnAccordion {
    padding-top:20px;
    padding-left: 50px;
    padding-right: 50px;
}

.bnFAQ {
    padding-left: 20px;
    padding-top:5px;
    display: none;
}

.benxtAppDesktop .bnFAQ {
    padding-left:55px;
}

.fBold {
    font-weight: 600;
    font-style: normal;
}

.fReg {
    font-weight: 300;
    font-style: normal;
}

.bnFooter {
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    height: 100px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    background-color: #0B6496;
    display: none;
}

.bnService {
    display: flex;
    flex-direction: row;
    align-self: center;
    padding-top: 30px;
    font-weight: normal;
}
 
.benxtAppDesktop .bnService {
    align-self: flex-end;
}

.bnEnt {
    font-size: 22px;
    padding-top: 10px;
    color: #EDEDED;
}

.bnRights {
    font-size: 12px;
    color: #AAAAAA;
}

.bnLink a:link, a:visited, a:active {
    color:darkgrey;
    text-decoration: none;
}

.bnLink a:hover {
    color: white;
}

.bnSep {
    color:darkgrey;
    text-align: center;
    width: 20px;
}
