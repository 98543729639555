.bnPage {
    /*background: rgb(239, 239, 244);*/
    width: 100%;
    height: 100%;
}

.bnFastDetectClientIPad .bnPage {
    overflow-x: hidden;
}

.bnPageCamera {
    width: 100%;
    height: 100%;
    background: black;
    overflow: visible;
}

.bnPageScroller {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    background: rgb(150, 150, 150);
}

.bnPageScrollerTitle {
    minHeight: 25px;
    width: 100%;
    text-align: center;
}

.bnPageContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 0px);
    background: rgb(150, 150, 150);

}

.bnPageBackground {
/*    background: black; */
    width: 100%;
    height: 100%;
}

.bnPageBack {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.bnPageBackLabel {
    color: #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 520;
}

.bnPageNextLabel {
    color: #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 520;
    cursor: pointer;
}


.bnPageCancelLabel {
    color: #c90000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}



.bnPageHeaderSpacer {
    width: 55px;
    height: 40px;
}


.bnPageTitle {
    font-weight: bold;
    font-size: 35px;
    text-align: left;
}

.bnPageSubtitle {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: rgb(112, 112, 112);
    line-height: 0.5;
}


.bnPageHeaderButtons {
    padding-top: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
}

.bnPageEmbed .bnPageHeaderButtons {
    padding-top: 0;
}

.bnPageEmbed .bnPageTitleRow {
    display: none;
}

.bnPageBackIcon {
    height: 35px;
    width: 33px;
    overflow: hidden;    
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bnPageBackIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.bnPageBackIcon svg {
    fill: #007bff;
    height: 45px;
    width: 45px;
}

.bnPageBackIcon .cls-2 {
    fill: #007bff;
    height: 40px;
    width: 40px;
}

.bnPageBackIcon .cls-3 {
    display: none;
}

.bnPageSearch {
    padding-top: 14px;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 15px;
}

.bnPageCustomSearch {
    padding-top: 14px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    padding-bottom: 15px;
}

.bnPageHeader {
}

.bnPageBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.bnPageTitleRow {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.bnPageUserIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.bnSubpageContainer {
    width: 100%;
    min-height: 100%;
}

.bnPageSubpage {
    background: rgb(150, 150, 150);
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
}

.bnPagePopupBg {
    width: 100%;
    height: 100%;
}

.bnPagePopup {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}


.bnPagePopupTop {
    height: 45px;
    width: 100%;
    left: 0;
    top: -45px;
    position: absolute;
    background: black;
}

.bnPagePopupBump {
    position: absolute;
    bottom: 0;
    left: 16px;
    height: 10px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    background: #bababa;
    display: none;
}

.uiTeTeAppAndroid.uiTeTeAppNative .bnPagePopupBump {
}
            
.bnPageTitleImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.bnPageTitleImage img {
    height: 85px;
    object-fit: cover;
}

.bnPageFooter {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.bnContentPage {
    height: 100%;
    width: 100%;
}

.bnPagePopupTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - 150px);
    text-align: center;
}
